import photo from "../../assets/photo.png";
import {
  HeaderImage,
  HeaderContentContainer,
  HeaderTitle,
  StyledHeader,
  ArrowIcon,
} from "./styled";
import Paragraph from "../../common/Paragraph/index";
import { UppercaseParagraph } from "../../common/Paragraph/styled";
import { Button } from "../../common/Button/styled";
import { Link } from "../../common/Link/styled";
import { useAnimations } from "./useAnimations";

const Header = () => {
  const { animations } = useAnimations();

  return (
    <StyledHeader id="home" style={animations}>
      <HeaderImage src={photo} alt="Joanna Panimasz" />
      <HeaderContentContainer>
        <UppercaseParagraph>Hello! My name is Joanna</UppercaseParagraph>
        <HeaderTitle>Front-end Developer</HeaderTitle>
        <Paragraph body="From Concept to Code" />
        <Link href="mailto:joanna@panimasz.pl">
          <Button>
            Hire me <ArrowIcon />
          </Button>
        </Link>
      </HeaderContentContainer>
    </StyledHeader>
  );
};

export default Header;
