import {
  SecondaryParagraph,
  StyledItem,
  UppercaseParagraph,
} from "../../common/Paragraph/styled";
import {
  StyledFooter,
  FooterContainer,
  ArrowIcon,
  StyledWrapper,
} from "./styled";
import { Link } from "../../common/Link/styled";
import { Button } from "../../common/Button/styled";

const Footer = () => {
  return (
    <StyledFooter id="contact">
      <FooterContainer>
        <UppercaseParagraph>Contact me</UppercaseParagraph>
        <SecondaryParagraph>
          Do you need a creative and technical mind? Hire a passionate{" "}
          <StyledItem>Front-end Developer.</StyledItem>
          <br />
          I'm ready to get started. Let's talk about collaboration.
        </SecondaryParagraph>
        <StyledWrapper>
          <Link
            href="mailto:joanna@panimasz.pl"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button secondary>
              Email
              <ArrowIcon />
            </Button>
          </Link>
          <Link
            href="https://github.com/panijoanna"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button secondary>
              Github
              <ArrowIcon />
            </Button>
          </Link>
        </StyledWrapper>
      </FooterContainer>
    </StyledFooter>
  );
};

export default Footer;
